import React from 'react';
import Home from './pages/Home';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {Route, Switch, BrowserRouter} from "react-router-dom";
import Countries from "./pages/countries";
import {StoreProvider} from "./store";
import Country from "./pages/country";
import Winery from "./pages/winery";
import About from './pages/About';

function App() {
  return (
    <StoreProvider>
      <div className="page">
        <BrowserRouter>
          <Header />
          <div className="content">
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/laender">
                <Countries />
              </Route>
              <Route path="/land/:countrySlug">
                <Country />
              </Route>
              <Route path="/weingut/:slug">
                <Winery />
              </Route>
              <Route path="/ueber-uns">
                <About />
              </Route>
            </Switch>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </StoreProvider>

  );
}

export default App;
