import React from "react";

export default function WineryTile({ to, image, children }) {
  return (
    <div>
      <a
        href={to}
        className="flex items-end justify-center w-full w-height bg-blue-100 h-64 text-5xl"
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: "cover",
          fontFamily: 'Oswald',
          textTransform: 'uppercase',
          fontWeight: 700,
          lineHeight: 0.9,
          fontSize: '4rem',
          color: 'rgba(255, 255, 255, 0.65)',
          boxShadow: "0px 2px 18px 0px rgba(0,0,0,0.3)",
        }}
      >
        {children}
      </a>
    </div>
  )
}
