import React from "react";
import { chunk } from "chunk";
import Animated from "../../components/Animated";

export default function WineryGrid({ children }) {
  return (
    <div>
      {children && chunk(children, 2).map((chunks, row) => (
        <div className="flex flex-row flex-wrap" key={`grid-row-${row}`}>
          {chunks.map((child, column) => {
            const isLeftComponent = column === 0;
            const isRightComponent = column === children.length - 1;
            return (
              <Animated
                hidden={{ x: isLeftComponent ? -100 : 100, opacity: 0 }}
                visible={{ x: 0, opacity: 1 }}
                key={`grid-cell-${row}-${column}`}
                className={`w-full sm:w-1/2 pt-8 ${isLeftComponent ? "md:pr-4" : isRightComponent ? "md:pl-4" : "md:px-4"}`}
              >
                {child}
              </Animated>
            );
          })}
        </div>
      ))}
    </div>
  )
}
