import React from "react";
import FullWidthSection from "../components/FullWidthSection";
import Section from '../components/Section';

export default function About() {
  return (
    <>
      <FullWidthSection
        sectionStyles={{ backgroundImage: 'url("' + process.env.PUBLIC_URL + '/images/trauben-gruen.jpeg")' }}
        overlayStyles={{ backgroundColor: 'rgba(75,87,62,0.65)' }}
      >
        <div style={{ maxWidth: '700px' }}>
          <h1 className="very-large-text">
            Über uns
          </h1>
          <p className="text-xl">
            Wie die Idee entstand, die schönsten Weingüter auf einer Seite zu vereinen?
          </p>
        </div>
      </FullWidthSection>

      <Section>
        <div className="flex flex-row">
          <div className="w-full lg:w-1/4 lg:pr-8">
            <img
                className="sticky top-32 mt-20 rounded shadow-xl"
                alt=""
                src={process.env.PUBLIC_URL + '/images/caro-2-scaled.jpg'}
            />
          </div>

          <div className="w-full lg:w-1/2">
            <h2 className="large-text">
              Die Idee, der Weg, unser Ziel!
            </h2>

            <p className="text-xl mt-4">
              Der Gedanke hinter dieser Seite ist schnell wiedergegeben:
            </p>

            <p className="text-xl mt-4">
              Unser Sommerurlaub führte uns in diesem Jahr in die Weinberge Italiens. Dort erlebten wir ein vollkommen neues Gefühl von Erholung.
              Ein traumhaftes Weingut in perfekter Symbiose zur Schönheit der Weinberge – die Ruhe und Abgeschiedenheit haben uns in so wenigen Tagen so sehr entschleunigt.
            </p>

            <p className="text-xl mt-4">
              Ist es nicht genau das, was viele Menschen heutzutage und mehr denn je als Gegenstück zum geschäftigen, hektischen Alltag suchen?
            </p>

            <div className="bg-opacity-60 bg-white border shadow-xl rounded-lg p-4 my-4 mx-4">
              <div className="inline-block items-center ml-8 text-xl">
                <div className="pl-4">Entspannung, </div>
                <div className="pl-8">eine kleine Auszeit, </div>
                <div className="pl-12">der Genuss des Schönen </div>
                <div className="pl-16">und des Besonderem im Leben. </div>
              </div>
            </div>

            <p className="text-xl mt-4">
              Wieder zuhause – im Alltag – angekommen, wusste ich, dass ich diese Art des Reisens unbedingt wiederholen möchte und
              begab mich auf die Suche nach einzigartigen, außergewöhnlich schönen Weingütern.
            </p>

            <p className="text-xl mt-4">
              Ich musste feststellen: Die Suche war zeitraubend – Zeit, die vielen von uns in ihren vollgestopften Tagesabläufen einfach fehlt.
              Natürlich habe ich großartige Weingüter gefunden, anderenfalls wäre diese Seite nicht online gegangen. Allerdings bin ich hauptsächlich
              in Blogs und Reiseberichten fündig geworden – mit Glück und Zufall auch auf den üblichen Buchungsportalen. Ich denke, dass die Auffindbarkeit
              eines Weingutes aber eben nicht nur ein Zufallstreffer sein sollte – das wird den unzähligen außergewöhnlichen Weingütern einfach nicht gerecht.
            </p>

            <p className="text-xl mt-4">
              Urlaub in den Weinbergen ist nicht nur ein normaler Urlaub, sondern etwas besonderes, was es verdient auch genauso dargestellt zu werden.
            </p>

            <div className="bg-opacity-60 bg-white border shadow-xl rounded-lg p-4 my-4 mx-4">
              <div className="inline-block items-center text-xl">

                Warum gibt es keine Website, die Weingüter weltweit mit all ihren Einzigartigkeiten zeigt und es den weinliebenden Reisefreudigen
                einfacher macht ihren nächsten Urlaub zu planen?
              </div>
            </div>

            <p className="text-xl mt-4">
              Damit war vinoinvita geboren!
            </p>

            <p className="text-xl mt-4">
              Das möchte Ich erreichen! Das möchten Wir erreichen. Das Wir besteht aus Mir, Carolin, kreativer Genussmensch mit einer Menge Ideen und Visionen
              und meinem Bruder Sören, der glücklicherweise in der Lage ist, diese Ideen umzusetzen.
            </p>

            <p className="text-xl mt-4">
              Wir setzten uns zusammen, sammelten Ideen, sprachen mit Leuten, traten mit ausgewählten Weingütern in Kontakt und hier sind wir nun
              – noch am Anfang unserer Reise. Hoffentlich einer langen, aufregenden Reise, auf der wir Weinliebhaber, jene die es werden könnten oder
              einfach all diejenigen, die sich in meinen Gedanken, in unserer Idee des Reisens, wiederfinden, mit einzigartigen Weingütern und
              ihren Menschen dahinter zusammenbringen können.
            </p>
          </div>

          <div className="w-full lg:w-1/4 lg:pl-8">
            <img
                className="sticky top-60 mt-60 rounded shadow-xl"
                alt=""
                src={process.env.PUBLIC_URL + '/images/caro-1-scaled.jpg'}
            />
          </div>
        </div>
      </Section>
    </>
  )
}
