import {action, makeAutoObservable} from "mobx";
import Prismic from '@prismicio/client';


export default class WineryStore {
  loading = false
  wineries = []
  country = null

  constructor(props) {
    makeAutoObservable(this);
  }

  async loadCountry(uid) {
    const apiEndpoint = 'https://vinoinvita.prismic.io/api/v2';
    const accessToken = 'MC5ZUjBRbHhJQUFDa0E2VHU4.77-9SO-_vQXvv70B77-977-977-9c--_ve-_vQLvv73vv71eZe-_ve-_ve-_vWpVIGUz77-977-977-977-9TWwX';
    const client = Prismic.client(apiEndpoint, { accessToken });
    const predicate = [
      Prismic.Predicates.at('document.type', 'country'),
      Prismic.Predicates.at('my.country.uid', uid)
    ];

    await client
      .query(predicate)
      .then(
          action("loadCountrySuccess", response => {
            this.country = response.results[0];
            console.log(this.country);
            this.loading = false;
          }),
          action("loadCountryError", err => {
            console.log(err);
            this.loading = false;
          })
      );

    return this.country;
  }

  async loadWineries() {
    const apiEndpoint = 'https://vinoinvita.prismic.io/api/v2';
    const accessToken = 'MC5ZUjBRbHhJQUFDa0E2VHU4.77-9SO-_vQXvv70B77-977-977-9c--_ve-_vQLvv73vv71eZe-_ve-_ve-_vWpVIGUz77-977-977-977-9TWwX';
    const client = Prismic.client(apiEndpoint, { accessToken });
    const predicate = [
      Prismic.Predicates.at('document.type', 'winery'),
      Prismic.Predicates.at('my.winery.country', this.country.id)
    ];

    await client
        .query(predicate)
        .then(
            action("loadWineriesSuccess", response => {
              this.wineries = response.results;
              console.log(this.wineries);
              this.loading = false;
            }),
            action("loadWineriesError", err => {
              console.log(err);
              this.loading = false;
            })
        );
  }

  async loadSingle(uid) {
    const apiEndpoint = 'https://vinoinvita.prismic.io/api/v2';
    const accessToken = 'MC5ZUjBRbHhJQUFDa0E2VHU4.77-9SO-_vQXvv70B77-977-977-9c--_ve-_vQLvv73vv71eZe-_ve-_ve-_vWpVIGUz77-977-977-977-9TWwX';
    const client = Prismic.client(apiEndpoint, { accessToken });
    const predicate = [
      Prismic.Predicates.at('document.type', 'winery'),
      Prismic.Predicates.at('my.winery.uid', uid)
    ];

    await client
        .query(predicate, { 'fetchLinks': 'country.map_file' })
        .then(
            action("loadSingleWinerySuccess", response => {
              this.winery = response.results[0];
              console.log(this.wineries);
              this.loading = false;
            }),
            action("loadSingleWineryError", err => {
              console.log(err);
              this.loading = false;
            })
        );

    return this.winery;
  }

  async load(uid) {
    await this.loadCountry(uid);
    await this.loadWineries();
  }
}
