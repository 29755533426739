import React from "react";
import PropTypes from "prop-types";

export default function FullWidthSection(props) {
  return (
    <div className="largeSection" style={props.sectionStyles}>
      <div className="overlay" style={props.overlayStyles}>
        <div className="container mx-auto">
          {props.children}
        </div>
      </div>
    </div>
  )
}

FullWidthSection.propTypes = {
  children: PropTypes.node.isRequired,
  sectionStyles: PropTypes.shape({
    backgroundImage: PropTypes.node,
  }),
  overlayStyles: PropTypes.shape({
    backgroundColor: PropTypes.string
  })
};
