import {observer} from "mobx-react";
import FullWidthSection from "../../components/FullWidthSection";
import React, {useEffect, useState} from "react";
import {useStore} from "../../store";
import {useParams} from "react-router-dom";
import Section from "../../components/Section";
import ImageGallery from "react-image-gallery";
import "../../gallery.css";
import RichText from 'prismic-reactjs/src/Component';
import Map from '../../components/Map';

const Winery = () => {
  const { wineryStore } = useStore();
  const { slug }  = useParams();
  const [ winery, setWinery] = useState();

  useEffect(() => {
    wineryStore.loadSingle(slug)
      .then(winery => {
        console.log(winery);
        setWinery(winery);
      })
  }, [ wineryStore, slug ]);

  return winery && (
    <>
      <FullWidthSection
        sectionStyles={{ backgroundImage: `url(${winery.data.header_image?.url})`}}
      >
        <div style={{ maxWidth: '700px' }}>
          <h1 className="very-large-text">
            {RichText.asText(winery.data.name)}
          </h1>
          <p className="text-xl">
            {RichText.asText(winery.data.subtitle)}
          </p>
        </div>
      </FullWidthSection>

      <Section>
        <div className="flex flex-row flex-wrap md:-mx-2 mt-4">

          {winery.data.body.filter(g => g.slice_type === "galleries")?.map(gallery => (
            <div className="w-full md:w-1/2 md:px-2 lg:w-1/3">
              <span className="large-text" style={{ fontWeight: '700' }}>
                {RichText.asText(gallery.primary.title)}
              </span>

              <div className="mt-2">
                <ImageGallery
                  showPlayButton={false}
                  showThumbnails={true}
                  useBrowserFullscreen={false}
                  showFullscreenButton={true}
                  items={gallery.items.map(item => {
                  return {
                    original: item.image?.url,
                    thumbnail: item.image.small?.url
                  }
                })} />
              </div>
            </div>
          ))}
        </div>
      </Section>

      <Section>
        <div className="flex flex-row flex-wrap mt-4">
          <div className="w-full lg:w-2/3 md:px-2">
            <span className="large-text">
              Über das Weingut
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.about)}
            </div>
          </div>

          <div className="w-full lg:w-1/3 md:px-2">
            <span className="large-text">
              Lage
            </span>

            <div className="mt-4">
              <Map
                  markers={[ winery.data.location ]}
                  source={"/mapsvg/maps/" + winery.data.country.data.map_file}
              />
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="flex flex-row flex-wrap">
          <div className="w-full md:w-1/2 md:px-2 lg:w-1/3 mt-4" style={{ minHeight: '200px' }}>
            <span className="large-text">
              Zimmerausstattung
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.room_facilities)}
            </div>
          </div>

          <div className="w-full md:w-1/2 md:px-2 lg:w-1/3 mt-4" style={{ minHeight: '200px' }}>
            <span className="large-text">
              Serviceangebote
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.service_offers)}
            </div>
          </div>

          <div className="w-full md:w-1/2 md:px-2 lg:w-1/3 mt-4" style={{ minHeight: '200px' }}>
            <span className="large-text">
              Verpflegung
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.meals_and_drinks)}
            </div>
          </div>

          <div className="w-full md:w-1/2 md:px-2 lg:w-1/3 mt-4" style={{ minHeight: '200px' }}>
            <span className="large-text">
              Check-In & -Out
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.checkin_and_out)}
            </div>
          </div>

          <div className="w-full md:w-1/2 md:px-2 lg:w-1/3 mt-4" style={{ minHeight: '200px' }}>
            <span className="large-text">
              Parken & Anreise
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.parking_and_arrival)}
            </div>
          </div>

          <div className="w-full md:w-1/2 md:px-2 lg:w-1/3 mt-4" style={{ minHeight: '200px' }}>
            <span className="large-text">
              WiFi
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.wifi)}
            </div>
          </div>

          <div className="w-full md:w-1/2 md:px-2 lg:w-1/3 mt-4" style={{ minHeight: '200px' }}>
            <span className="large-text">
              Haustiere
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.pets)}
            </div>
          </div>

          <div className="w-full md:w-1/2 md:px-2 lg:w-1/3 mt-4" style={{ minHeight: '200px' }}>
            <span className="large-text">
              Gesprochene Sprachen
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.languages)}
            </div>
          </div>

          <div className="w-full md:w-1/2 md:px-2 lg:w-1/3 mt-4" style={{ minHeight: '200px' }}>
            <span className="large-text">
              Zimmerausstattung
            </span>

            <div className="mt-4">
              {RichText.render(winery.data.room_facilities)}
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default observer(Winery);
