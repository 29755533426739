import {action, makeAutoObservable} from "mobx";
import Prismic from '@prismicio/client'

export default class CountryStore {
  loading = false
  countries = []

  constructor() {
    makeAutoObservable(this);
  }

  async load() {
    this.loading = true;

    const apiEndpoint = 'https://vinoinvita.prismic.io/api/v2';
    const accessToken = 'MC5ZUjBRbHhJQUFDa0E2VHU4.77-9SO-_vQXvv70B77-977-977-9c--_ve-_vQLvv73vv71eZe-_ve-_ve-_vWpVIGUz77-977-977-977-9TWwX';
    const client = Prismic.client(apiEndpoint, { accessToken });
    const predicate = Prismic.Predicates.at('document.type', 'country');

    client
      .query(predicate)
      .then(
        action("loadSuccess", res => {
          this.countries = res.results;
          console.log(this.countries);
          this.loading = false;
        }),
        action("loadError", err => {
          console.log(err);
          this.loading = false;
        })
      );
  }
}
