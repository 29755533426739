import React from "react";
import FullWidthSection from "../components/FullWidthSection";

export default function Home() {
  return (
    <>
      <FullWidthSection
        sectionStyles={{ backgroundImage: 'url("' + process.env.PUBLIC_URL + '/images/trauben-blau.jpeg")' }}
        overlayStyles={{ backgroundColor: 'rgba(75,87,62,0.65)' }}
      >
        <div style={{ maxWidth: '700px' }}>
          <h1 style={{ fontSize: '3rem', fontWeight: '700' }}>
            Geh' doch dahin, wo der
            <span style={{ fontFamily: "Sacramento", textTransform: "none", fontSize: '5rem', margin: '0px 15px', fontWeight: 'normal' }}>Wein</span>
            wächst!
          </h1>
        </div>
      </FullWidthSection>
    </>
  )
}
