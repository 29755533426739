import CountryStore from "./CountryStore";
import {makeAutoObservable} from "mobx";
import LocalizerStore from "./LocalizerStore";
import WineryStore from "./WineryStore";

export default class RootStore {
  countryStore
  localizerStore
  wineryStore

  constructor() {
    makeAutoObservable(this);

    this.countryStore = new CountryStore()
    this.localizerStore = new LocalizerStore()
    this.wineryStore = new WineryStore()
  }
}
