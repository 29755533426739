import React, {useEffect} from "react";
import FullWidthSection from "../../components/FullWidthSection";
import Section from "../../components/Section";
import WineryGrid from "./WineryGrid";
import {useStore} from "../../store";
import WineryTile from "./WineryTile";
import {observer} from "mobx-react";

const Countries = () => {
  const { countryStore } = useStore();

  useEffect(() => {
    countryStore.load();
  }, [countryStore]);

  return (
    <>
      <FullWidthSection
        sectionStyles={{ backgroundImage: 'url("' + process.env.PUBLIC_URL + '/images/weingut_pool_wenig_himmel-scaled-1.jpg")'}}
      >
        <div style={{ maxWidth: '700px' }}>
          <h1 className="very-large-text">Weingüter</h1>
          <p className="text-xl">
            Du hast dir die Auszeit verdient! Entspannter kannst du deinen Urlaub nicht verbringen: die schönsten
            Unterkünfte direkt am Weingut oder in den Weinbergen stellen wir hier vor.
          </p>
        </div>
      </FullWidthSection>

      <Section>
        <WineryGrid>
          {countryStore.countries.map(country => (
            <WineryTile
              key={country.uid}
              image={country.data.header_image.small.url}
              to={"/land/" + country.uid}
            >
              {country.data.name}
            </WineryTile>
          ))}
        </WineryGrid>
      </Section>
    </>
  )
}

export default observer(Countries);
