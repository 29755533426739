import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container mx-auto text-sm">
        <a href="/impressum">
          Impressum
        </a>
        <a href="/datenschutz">
          Datenschutz
        </a>
      </div>
    </footer>
  );
}
