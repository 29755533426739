import React, {useEffect } from "react";
import {observer} from "mobx-react";
import FullWidthSection from "../../components/FullWidthSection";
import Section from "../../components/Section";
import WineryGrid from "../countries/WineryGrid";
import WineryTile from "../countries/WineryTile";
import {useStore} from "../../store";
import { useParams} from "react-router-dom";
import Map from "../../components/Map";
import RichText from 'prismic-reactjs/src/Component';

const Country = () => {
  const { countrySlug }  = useParams();
  const { wineryStore } = useStore();
  const { country } = wineryStore;

  useEffect(() => {
    wineryStore.load(countrySlug);
  }, [ countrySlug, wineryStore ]);


  console.log(country?.data);

  return country && (
    <>
      <FullWidthSection
        sectionStyles={{ backgroundImage: `url(${country.data.header_image.url})`}}
      >
        <div style={{ maxWidth: '700px' }}>
          <h1 className="very-large-text">
            {RichText.asText(country.data.title)}
          </h1>
          <p className="text-xl">
            {RichText.asText(country.data.subtitle)}
          </p>
        </div>

        <div style={{
          position: "absolute",
          top: "150px",
          right: "100px",
        }}>
          <Map
            source={"/mapsvg/maps/" + country.data.map_file}
            markers={wineryStore.wineries.map(winery => winery.data.location)}
          />
        </div>

      </FullWidthSection>

      <Section>
        <WineryGrid>
          {wineryStore.wineries.map(winery => (
            <WineryTile
              key={winery.uid}
              image={winery.data.header_image?.small.url}
              to={"/weingut/" + winery.uid}
            >
              <RichText render={winery.data.name} />
            </WineryTile>
          ))}
        </WineryGrid>
      </Section>
    </>
  )
};

export default observer(Country);
