import React from "react";
import PropTypes from "prop-types";

export default function Section(props) {
  return (
    <div className="section py-8" style={props.sectionStyles}>
      <div className="container mx-auto">
        {props.children}
      </div>
    </div>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  sectionStyles: PropTypes.shape({
    backgroundImage: PropTypes.node,
  }),
};
