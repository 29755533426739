import React from "react";

/* global $ */
export default class Map extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false;
  }

  map = null;

  componentDidMount() {
    this.map = window.$("#mapsvg").mapSvg({
      width: 350,
      height: 350,
      zoom: {on: false, limit: [0, 10], delta: 2, buttons: {on: true, location: "right"}, mousewheel: true},
      scroll: {on: false, limit: false, background: false, spacebar: false},
      tooltips: {mode: "off", on: false, priority: "local", position: "bottom-right"},
      popovers: {
        mode: "off",
        on: false,
        priority: "local",
        position: "top",
        centerOn: true,
        width: 300,
        maxWidth: 50,
        maxHeight: 50,
        resetViewboxOnClose: true,
        mobileFullscreen: true
      },
      source: this.props.source,
      responsive: false,
      onClick: null,
      colors: {
        background: 'transparent'
      },
      mouseOver: () => {
        $("#mapsvg svg").css('opacity', 1);
      },
      mouseOut: () => {
        $("#mapsvg svg").css('opacity', 0.5);
      }
    });

    this.props.markers?.forEach(m => {
      this.map.markerAdd({
        geoCoords: [m.latitude, m.longitude],
        mapsvg: this.map,
      });
    });

  }

  render() {
    return (
      <div>
        <div id="mapsvg" style={{
          opacity: 1,
        }} />
      </div>
    );
  }
}
