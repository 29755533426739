import React from "react";
import {motion, useAnimation} from "framer-motion";
import VisibilitySensor from "react-visibility-sensor";

export default function Animated({ children, visible, hidden, ...props }) {
  const controls = useAnimation();

  const visiblityChanged = (isVisible) => {
    if (isVisible) {
      controls.start("visible");
    }
  };

  return (
    <VisibilitySensor onChange={visiblityChanged} partialVisibility>
      <motion.div
        animate={controls}
        initial="hidden"
        variants={{
          visible,
          hidden
        }}
        transition={{ duration: 0.7 }}
        {...props}
      >
        {children}
      </motion.div>
    </VisibilitySensor>
  )
}
