import {makeAutoObservable} from "mobx";


export default class LocalizerStore {
  language = "de";
  fallbackLanguage = "en";

  constructor() {
    makeAutoObservable(this);
  }

  localize(array) {
    if (!array) {
      return array;
    }

    return array.find(element => element.lang === this.language) ??
      array.find(element => element.lang === this.fallbackLanguage);
  }
}
