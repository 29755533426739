import React from 'react'
import RootStore from "./stores/RootStore";

const storeContext = React.createContext(null)

export function StoreProvider({ children }) {
  const store = new RootStore();
  return (
    <storeContext.Provider value={store}>
      {children}
    </storeContext.Provider>
  );
}

export function useStore() {
  const store = React.useContext(storeContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}
