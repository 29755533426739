import React, {useEffect, useState, useCallback} from "react";
import Logo from "../logo.svg";
import LogoText from "../logo_text.svg";

export default function Header() {
  const [isSticky, setSticky] = useState(undefined);

  const onScroll = useCallback(e => {
    const willBeSticky = document.documentElement.scrollTop > 50;
    if (willBeSticky !== isSticky) {
      setSticky(willBeSticky);
    }
  }, [isSticky]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  return (
    <header className={isSticky ? "header sticky" : "header"}>
      <div className="container mx-auto flex flex-row items-center">
        <div className="logo">
          <img alt="" src={Logo} id="logoImage" />
          <img alt="" src={LogoText} id="logoText" />
        </div>

        <div className="menu">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/laender">Weingüter</a>
            </li>
            <li>
              <a href="/ueber-uns">Über uns</a>
            </li>
            <li>
              <a href="/kontakt">Kontakt</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
